<template>
  <b-form @submit="search">
    <b-form-group horizontal>
      <b-input-group>
        <b-form-input v-model="q" placeholder="Type to search" />
          <b-btn :disabled="!q" @click="search">search</b-btn>
      </b-input-group>
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  name: 'SearchForm',
  data () {
    return {
      q: ''
    }
  },
  methods: {
    search: function (evt) {
      evt.preventDefault()
      this.$router.push({ name: 'Search', params: { q: this.q } })
    }
  }
}
</script>
